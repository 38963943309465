import React, { useState } from "react";
import logo from "../images/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import ResBar from "./ResBar";

function Navbar() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  return (
    <>
      <ResBar show={show} setShow={setShow} />
      <div className="absolute max-[780px]:px-10  flex px-20 pt-4  justify-between w-full">
        <img
          onClick={() => navigate("/")}
          src={logo}
          className="cursor-pointer w-40 h-10 max-[650px]:w-32 max-[650px]:h-8"
          alt="logo_img"
        />
        <div className="flex items-center max-[740px]:hidden gap-6 text-sm rounded-full  bg-opacity-65 text-white bg-black b py-3 px-5">
          <NavLink to="/" className="">
            Home
          </NavLink>
          <NavLink to="/productpage">Products</NavLink>
          <NavLink to="/services">Services</NavLink>
          <NavLink to="/about">About Us</NavLink>
          <NavLink to="/contactpage">Contact Us</NavLink>
          <NavLink to="/faq_?">FAQ</NavLink>
          <NavLink to="/blog">Blog</NavLink>
        </div>
        <FiMenu
          onClick={() => setShow(true)}
          className="text-white text-3xl hidden max-[740px]:block"
        />
      </div>
    </>
  );
}

export default Navbar;
