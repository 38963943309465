import React from "react";
import nav from "../../images/blog/nav.png";
import { useNavigate } from "react-router-dom";
import cloudbanner from "../../images/blog/cloudbanner.png";
import banner from "../../images/products/product_banner.jpg";
import cyberbanner from "../../images/blog/cyberbanner.png";
import aibanner from "../../images/blog/aiblog.jpg";

const data = [
  {
    id: "1",
    route: "/cloudsec",
    coverimage: cloudbanner,
    title: "Cloud Security 101: Protecting Your Data in the Cloud",
  },
  {
    id: "2",
    route: "/tech_blog",
    coverimage: banner,
    title:
      "How to Secure Your Network -A Comprehensive Guide to Protecting Your Digital Assets",
  },
  {
    id: "3",
    route: "/topcybersec",
    coverimage: cyberbanner,
    title:
      "Top Cybersecurity Trends for 2024: What Your Business Needs to Know",
  },
  {
    id: "4",
    route: "/artint",
    coverimage: aibanner,
    title: "How Artificial Intelligence is Revolutionizing Network Security",
  },
];
function Blog() {
  const navigate = useNavigate();
  return (
    <div className="blog w-full h-full flex flex-col max-[700px]:px-4  pt-14 px-10">
      <div className="w-full  mt-12 grid grid-cols-2  max-[650px]:grid-cols-1 ">
        <div className="w-full h-full">
          <img
            src={nav}
            alt="img"
            className="w-full h-4/5 max-[1160px]:h-[90%]"
          />
        </div>
        <div className="w-full h-full">
          <div className="w-full grid grid-cols-2 p-2 gap-6 max-[650px]:gap-3 max-[650px]:grid-cols-1">
            {data.map((data) => (
              <div
                key={data.id}
                onClick={() => {
                  navigate(data.route);
                }}
                className="cursor-pointer w-full rounded-xl bg-opacity-15 bg-stone-600 shadow-xl"
              >
                <img
                  src={data.coverimage}
                  alt="img"
                  className="w-full h-[65%] rounded-t-xl"
                />
                <div className="p-3 text-sm text-white max-[850px]:text-[8px] max-[850px]:p-2">
                  {data.title}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <div className="w-full px-10 mt-7 max-[650px]:px-4 py-5 bg-stone-700 rounded-2xl bg-opacity-35 ">
        <p className=" text-white text-2xl font-medium max-[650px]:text-2xl">
          Trending Topics
        </p>
        <div className="topic-scroll py-4 w-full flex jusitfy-center items-center gap-10 overflow-auto">
          {TrendigTopic.map((data) => (
            <div>
              <img
                key={data.id}
                src={data.coverimage}
                alt="img"
                onClick={() => {
                  navigate("/artint");
                  setBlog(data.id);
                }}
                className="w-[230px] cursor-pointer flex-none h-[140px] max-[650px]:w-[200px] max-[650px]:h-[150px] rounded-lg shadow-lg"
              />
            </div>
          ))}
        </div>
      </div> */}
      {/* <div className="w-full p-5 my-6 ">
        <p className="text-white text-3xl font-medium">Top Stories</p>
        <div className="flex items-center gap-5 my-6">
          <button className="text-white bg-cyan-600 rounded-full py-2 px-7 text-sm">
            Tech
          </button>
          <button className="text-white bg-trnasparent border border-cyan-500 rounded-full py-2 px-4 text-sm">
            Software
          </button>
        </div>
        <div className="w-full grid grid-cols-2 max-[700px]:grid-cols-1 gap-5 ">
          {blog_data.map((blog) => (
            <div key={blog.id} className="w-full flex gap-4 items-start">
              <img
                src={blog.coverimage}
                alt=""
                className="w-32 h-full rounded-md cursor-pointer"
                onClick={() => {
                  navigate("/artint");
                  setBlog(blog.id);
                }}
              />
              <div className="flex flex-col items-start gap-1 text-lg">
                <span className="text-cyan-400 font-medium text-md">
                  {blog.category}
                </span>
                <p className="text-white font-medium text-sm max-[650px]:text-sm">
                  {blog.title}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
}

export default Blog;
