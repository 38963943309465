import React, { useEffect } from "react";
import laptop from "../../images/blog/laptop.png";
import aibanner from "../../images/blog/aiblog.jpg";
import aibannera from "../../images/blog/aiblogg.jpg";

import { useData } from "../../blogContext/Context";
import { useState } from "react";
import AOS from "aos";
import blog3 from "../data/format2/blog3.json";
import "aos/dist/aos.css";
function AInt() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  const [blog] = useState(blog3[0]);

  return (
    <div className="blog w-full ">
      <div className="w-full h-screen ">
        <img src={aibanner} alt="img" className="w-full h-[95%]" />
        <div className=" w-full absolute flex flex-col my-5 gap-4 px-10 bottom-10 ">
          <span className="text-yellow-400 text-lg font-medium">
            {blog.category}
          </span>
          <p className="text-white text-3xl font-semibold pb-8 border-b-2 border-white">
            {blog.title}
          </p>
        </div>
      </div>
      <div className="flex max-[900px]:flex-wrap w-full justify-between">
        <div className="w-4/5 max-[900px]:w-full flex flex-col gap-8 text-white text-md p-8">
          <p>{blog.description}</p>
          <img
            data-aos="zoom-in"
            src={aibannera}
            alt="img"
            className="w-full h-3/5 rounded-lg"
          />
          {blog.number.map((num, index) => (
            <div key={index}>
              <h1
                data-aos="fade-up"
                className="text-white text-2xl font-medium"
              >
                {num.heading}
              </h1>
              <br />
              <p data-aos="fade-up">{num.description}</p>
            </div>
          ))}

          <h1 data-aos="fade-up" className="text-white text-2xl font-medium">
            Conclusion
          </h1>
          <p data-aos="fade-up" className="text-white">
            {blog.conclusion}
          </p>
        </div>
        <div className="w-2/5 max-[900px]:w-full p-4  ">
          <div className="w-full mb-14 grid grid-cols-2 max-[400px]:pb-0 max-[400px]:grid-cols-2 max-[900px]:grid-cols-4 gap-3 max-[900px]:pb-24">
            {/* {
            blogs.map((blog) => (
              <img data-aos='zoom-in' key={blog.id} src={blog.coverimage} alt="img" className="w-full h-full rounded-lg cursor-pointer" />
            ))
          } */}
          </div>
          {/* <div className="w-full px-8 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col items-center gap-6 rounded-xl">
            <p className="text-white font-medium text-xl text-center">
              Read More
            </p>
            <div className="w-full grid grid-cols-1 max-[550px]:gap-2 gap-5 max-[900px]:grid-cols-2">
              <div data-aos='fade-up' className="w-full rounded-xl bg-opacity-45 bg-stone-600 shadow-xl">
                <img  src={laptop} alt="img" className="w-full rounded-t-xl" />
                <div className="p-4 text-sm text-white max-[900px]:p-2 max-[550px]:text-xs">
                  <p>Cloud Security 101: Protecting Your Data in the Cloud </p>
                </div>
              </div>
              <div data-aos='fade-up' className="w-full h-full rounded-xl bg-opacity-45 bg-stone-600 shadow-xl">
                <img
                  src={cloudbanner}
                  alt="img"
                  className="w-full rounded-t-xl"
                />
                <div className="p-4 text-sm text-white max-[900px]:p-2  max-[550px]:text-xs">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing
                    elitLaboriosam .
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default AInt;
